import PropTypes from 'prop-types';

// material-ui
import {useTheme} from '@mui/material/styles';
import {Box,Drawer,ListItemButton,ListItemIcon,ListItemText,Typography,useMediaQuery} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import {BrowserView,MobileView} from 'react-device-detect';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import {drawerWidth} from 'store/constant';
import {useSelector} from 'react-redux';
import {IconLogout} from '@tabler/icons';
import axios from 'axios';
import {useNavigate} from 'react-router';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar=({drawerOpen,drawerToggle,window}) => {
  const theme=useTheme();
  const matchUpMd=useMediaQuery(theme.breakpoints.up('md'));
  const leftDrawerOpened=useSelector((state) => state.customization.opened);
  const navigate=useNavigate();

  const drawer=(
    <>
      <Box sx={{display: {xs: 'block',md: 'none'}}}>
        <Box sx={{display: 'flex',p: 2,mx: 'auto'}}>
          <LogoSection />
        </Box>
      </Box>
      <BrowserView>
        <PerfectScrollbar
          component="div"
          style={{
            height: !matchUpMd? '100vh':'calc(100vh - 88px)',
          }}
        >
          <MenuList />
          <ListItemButton
            sx={{
              borderRadius: `10 px`,
              mb: 0.5,
              alignItems: 'flex-start',
              backgroundColor: 'inherit',
              py: 1.25,
              pl: `${24}px`
            }}
            onClick={async () => {
              try {
                axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/logout`,{},{
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                  }
                })
                navigate('/login')
              } catch (e) {
                console.log(e);
                navigate('/login')
              }
            }}
          >
            <ListItemIcon sx={{my: 'auto',minWidth: 36}}>{<IconLogout />}</ListItemIcon>
            <ListItemText
              primary={
                <Typography variant={'body1'} color="inherit">
                  Logout
                </Typography>
              }
            />
          </ListItemButton>
        </PerfectScrollbar>
      </BrowserView>
      <MobileView>
        <Box sx={{px: 2}}>
          <MenuList />
        </Box>
      </MobileView>
    </>
  );

  const container=window!==undefined? () => window.document.body:undefined;

  return (
    <Box component="nav" sx={{flexShrink: {md: 0},width: matchUpMd? drawerWidth:'auto'}} aria-label="mailbox folders">
      <Drawer
        container={container}
        variant={matchUpMd? 'persistent':'temporary'}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            boxShadow: leftDrawerOpened? '9px 10px 15px -3px rgba(0,0,0,0.1)':'',
            borderRight: 'none',
            [theme.breakpoints.up('md')]: {
              top: '84px',
            }
          },
        }}
        ModalProps={{keepMounted: true}}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

Sidebar.propTypes={
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object
};

export default Sidebar;

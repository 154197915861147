import axios from 'axios';
import React,{createContext,useState,useContext} from 'react';
import * as Icons from '@mui/icons-material';
import {useLocation,useNavigate} from 'react-router';
import {useDispatch} from 'react-redux';
import {showSnackbar} from 'store/SnackBar/actions';
import {useEffect} from 'react';

export const ConfigMenuContext=createContext();

export const useConfigMenu=() => useContext(ConfigMenuContext);

function Icon({iconName}) {
  const IconComponent=Icons[iconName];
  if(!IconComponent) {
    // Ícone não encontrado, você pode renderizar um ícone padrão ou tratar de outra forma
    return null;
  }
  return <IconComponent strokeWidth={1.5} size="1.3rem" style={{marginTop: 'auto',marginBottom: 'auto'}} />;
}

export const ConfigMenuProvider=({children}) => {
  const [response,setResponse]=useState([]);
  const [loading,setLoading]=useState(true);
  const [items,setItems]=useState([]);
  const [user,setUser]=useState();
  const [language,setLanguage]=useState(null);
  const navigate=useNavigate();
  const pathname=useLocation().pathname;
  const dispatch=useDispatch();
  const itensKeys=['configuracoes','cadastros','administrativo','financeiro','juridico','produtos','telefonia','aplicativos','comercial','pinepr','suporte','servicos','projetos','ti','backoffice',"representantes","vendas"];

  useEffect(() => {
    FetchMenu();
  },[]);

  const FetchMenu=async (isLogin=false) => {
    try {
      if(pathname.includes('/dashboard/')||(pathname.includes('/login')&&!isLogin)||pathname==='/'&&!isLogin) {
        return;
      }
      const responseData=await axios.get(`${process.env.REACT_APP_BACKEND_URL}/config/menu`);
      const responseUser=await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/me`,{},{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
      })
      localStorage.setItem('tema',JSON.stringify(responseUser.data.color));
      localStorage.setItem('default',JSON.stringify(responseData.data.data.objetos[0].config.colors.default));
      localStorage.setItem('colors',JSON.stringify(responseData.data.data.objetos[0].config.colors));
      const languages={
        'pt-br': {
          usuarios: {
            title: 'Usuários',
            cadastrar: 'Cadastrar Usuário',
            editar: 'Editar Usuário',
            visualizar: 'Visualizar Usuário',
            table: {
              head: {
                id: 'ID',
                nome: 'Nome',
                login: 'Login',
                admin: 'Admin',
                ativo: 'Ativo',
                lastLogin: 'Último Login',
                opcoes: 'Opc.',
              },
              exibir: 'Exibir',
              filtrar: 'Filtrar',
              limpar: 'Limpar',
              erros: {
                required: 'Este campo é obrigatório',
                min3: 'Mínimo de 3 caracteres',
                min8: 'Mínimo de 8 caracteres',
                email: 'Email inválido',
                cpf: 'CPF inválido',
                error: {
                  list: 'Erro ao listar',
                  delete: 'Erro ao deletar',
                  filtros: 'Erro ao filtrar',
                  add: 'Erro ao realizar cadastro',
                },
              },
              success: {
                delete: 'deletado com sucesso',
                add: 'cadastrado com sucesso',
              },
            },
            form: {
              nome: 'Nome',
              email: 'Email',
              loginWithEmail: 'Login com Email?',
              login: 'Login',
              senha: 'Senha',
              cpf: 'CPF',
              contato: 'Contato',
              ativo: 'Ativo?',
              admin: 'Admin?',
              menu_lateral: 'Menu Lateral?',
              alterar_senha: 'Alterar Senha?',
              editar_perfil: 'Editar Perfil?',
              fileTitle: 'Enviar Imagem',
              imageProfile: 'Imagem de Perfil',
            },
            button: {
              save: 'Salvar',
            }
          },
          grupos: {
            title: 'Grupos',
            cadastrar: 'Cadastrar Grupo',
            editar: 'Editar Grupo',
            visualizar: 'Visualizar Grupo',
            table: {
              head: {
                id: 'ID',
                nome: 'Nome',
                admins: 'Administradores',
                users: 'Usuários',
                date: 'Data de Criação',
                opcoes: 'Opc.',
              },
            },
            errors: {
              add: 'Erro ao adicionar grupo',
              required: 'Este campo é obrigatório',
            },
            success: {
              add: 'Grupo adicionado com sucesso',
            },
            steps: {
              info: 'Informações do Grupo',
              users: 'Usuários do Grupo',
            },
            form: {
              nome: 'Nome do Grupo',
              tipo: 'Tipo de Grupo',
              descricao: 'Descrição',
              users: 'Usuários',
              empresas: 'Empresa',
              admin: 'Usuário é Administrador?',
              title: 'Usuários do Grupo'
            },
            button: {
              finish: 'Finalizar',
              back: 'Voltar',
              next: 'Próximo',
            }
          },
          tarefas: {
            title: 'Tarefas',
            titleName: 'Tarefa',
            new: 'Nova Tarefa',
            loadMore: 'Carregar mais',
            label: 'Label',
            responsible: 'Responsáveis',
            search: 'Pesquisar',
            button: {
              edit: 'Editar',
              cancel: 'Cancelar',
              save: 'Salvar',
            },
            filter: {
              title: 'Filtros',
              favorites: 'Favoritos',
              finished: 'Finalizadas',
              files: 'Arquivos',
              recorrente: 'Recorrente',
              notify: 'Notificar',
              clear: 'Limpar Filtros',
            },
            erros: {
              required: 'Este campo é obrigatório',
              error: {
                add: 'Erro ao adicionar tarefa',
                edit: 'Erro ao atualizar tarefa',
                delete: 'Erro ao deletar tarefa',
                list: 'Erro ao listar tarefas',
                favorite: 'Erro ao favoritar tarefa',
                complete: 'Erro ao completar tarefa',
                loadMore: 'Carregar mais',
                label: 'Label',
                responsible: 'Responsáveis',
                search: 'Pesquisar',
                anexo: 'Erro ao adicionar anexo',
                min3: 'Mínimo de 3 caracteres',
                dateInicial: 'Data Inicial é obrigatória',
                dateFinal: 'Data Final é obrigatória',
                downloadFile: 'Erro ao baixar arquivo',
                deleteFile: 'Erro ao deletar arquivo',
                subTaskAdd: 'Erro ao adicionar sub tarefa',
                subTaskEdit: 'Erro ao editar sub tarefa',
                subTaskDelete: 'Erro ao deletar sub tarefa',
                commentsAdd: 'Erro ao adicionar comentário',
                commentsEdit: 'Erro ao editar comentário',
                commentsDelete: 'Erro ao deletar comentário',
                flagsAdd: 'Erro ao adicionar flag',
                flagsEdit: 'Erro ao editar flag',
                flagsDelete: 'Erro ao deletar flag',
              },
              notFound: 'Nenhuma tarefa encontrada',
            },
            success: {
              add: 'Tarefa adicionada com sucesso',
              edit: 'Tarefa atualizada com sucesso',
              delete: 'Tarefa deletada com sucesso',
              favorite: 'Tarefa favoritada com sucesso',
              complete: 'Tarefa completada com sucesso',
              anexo: 'Anexo adicionado com sucesso',
              downloadFile: 'Arquivo baixado com sucesso',
              deleteFile: 'Arquivo deletado com sucesso',
              subTaskAdd: 'Sub Tarefa adicionada com sucesso',
              subTaskEdit: 'Sub Tarefa editada com sucesso',
              subTaskDelete: 'Sub Tarefa deletada com sucesso',
              commentsAdd: 'Comentário adicionado com sucesso',
              commentsEdit: 'Comentário editado com sucesso',
              commentsDelete: 'Comentário deletado com sucesso',
              flagsAdd: 'Flag adicionada com sucesso',
              flagsEdit: 'Flag editada com sucesso',
              flagsDelete: 'Flag deletada com sucesso',
            },
            form: {
              title: 'Titulo',
              responsible: 'Responsável',
              dateInicial: 'Data Inicial',
              dateFinal: 'Data Final',
              description: 'Descrição da Tarefa',
              notify: 'Notificar?',
              recorrente: 'Recorrente?',
              titleFlag: 'Titulo da Etiqueta',
            },
            files: {
              title: 'Anexos',
              addFiles: 'Adicionar Arquivos',
              added: 'Arquivos Adicionados',
            },
            subTasks: {
              title: 'Sub Tarefas',
              new: 'Nova Sub Tarefa',
            },
            comments: {
              creatorOf: 'Criou esse comentário',
              editComment: 'Editar Comentário',
              writeComment: 'Escreva um comentário',
              comment: 'Comentar'
            },
            flags: {
              new: 'Nova Etiqueta',
              bgColor: 'Cor de Fundo',
            }
          },
          departamentos: {
            title: 'Departamentos',
            erros: {
              required: 'Este campo é obrigatório',
              error: {
                add: 'Erro ao adicionar departamento',
                edit: 'Erro ao atualizar departamento',
                delete: 'Erro ao deletar departamento',
                list: 'Erro ao listar departamentos',
                min3: 'Mínimo de 3 caracteres',
              },
              notFound: 'Nenhum departamento encontrado',
            },
            success: {
              add: 'Departamento adicionado com sucesso',
              edit: 'Departamento atualizado com sucesso',
              delete: 'Departamento deletado com sucesso',
            },
            form: {
              titleAdd: 'Adicionar Departamento',
              titleEdit: 'Editar Departamento',
              departamento: 'Departamento',
              empresa: 'Empresa',
              descricao: 'Descrição',
              ativo: 'Ativo?',
            },
            button: {
              add: 'Adicionar',
              edit: 'Editar',
              delete: 'Deletar',
              save: 'Salvar',
            }
          },
          programas: {
            title: 'Programas',
            cadastrar: 'Cadastrar Programa',
            editar: 'Editar Programa',
            visualizar: 'Visualizar Programa',
            table: {
              head: {
                id: 'ID',
                modulo: 'Módulo',
                programa: 'Programa',
                rota: 'Rota',
                exibirMenu: 'Exibir Menu',
                filtros: 'Filtros',
                acoes: 'Ações',
                interacoes: 'Interações',
                functions: 'Funções',
                icone: 'Ícone',
                opcoes: 'Opc.',
              },
            },
            error: {
              required: 'Este campo é obrigatório',
              add: 'Erro ao adicionar programa',
            },
            success: {
              add: 'Programa adicionado com sucesso',
            },
            form: {
              modulo: 'Módulo',
              controller: 'Cadastrar controller automaticamente?',
              exibirMenu: 'Exibir no Menu?',
              programa: 'Programa',
              rota: 'Rota',
              icone: 'Ícone',
              botoesMenu: 'Botões do Menu',
              acoes: 'Ações',
              interacoes: 'Interações',
              opcoes: 'Opções',
              banco: 'Banco de Dados',
              tabela: 'Tabela',
              pasta: 'Pasta',
              oque: 'oque',
              comparativo: 'comparativo',
              com: 'com',
              campo: 'Campo',
              label: 'Label',
              comparador: 'Comparador',
              tipoCampo: 'Tipo de Campo',
              classe: 'Classe',
            },
            button: {
              save: 'Salvar',
            }
          },
          modulos: {
            title: 'Modulos',
            cadastrar: 'Cadastrar Modulo',
            editar: 'Editar Modulo',
            visualizar: 'Visualizar Modulo',
            table: {
              head: {
                id: 'ID',
                modulo: 'Módulo',
                rota: 'Rota',
                menu: 'Menu',
                subMenu: 'Submenu',
                opcoes: 'Opc.',
              },
            },
            error: {
              required: 'Este campo é obrigatório',
              add: 'Erro ao adicionar programa',
              edit: 'Erro ao editar programa',
              max50: 'Máximo de 50 caracteres',
            },
            success: {
              add: 'Programa adicionado com sucesso',
              edit: 'Programa editado com sucesso',
            },
            form: {
              modulo: 'Módulo',
              rota: 'Rota',
              menu: 'Exibir no Menu?',
              prefixo: 'Inserir Prefixo',
              controller: 'Criar Controller automaticamente?',
            },
            button: {
              save: 'Salvar',
            }
          },
          propostas: {
            title: 'Propostas',
            newVersion: 'Nova Versão',
            cadastrar: 'Nova Proposta',
            editar: 'Editar Proposta',
            visualizar: 'Visualizar Proposta',
            dias: 'dias',
            dia: 'dia',
            di: 'di',
            meses: 'meses',
            mese: 'mese',
            mes: 'mês',
            me: 'mê',
            currency: 'R$',
            addBySystem: 'Adicionado pelo sistema',
            nothing: 'Nenhuma versão cadastrada',
            addVersion: 'Adicionar Versão',
            addMore: 'Adicionar Mais',
            table: {
              head: {
                id: 'ID',
                titulo: 'Título',
                ativo: 'Ativo',
                cadastros: 'Cadastros',
                empresas: 'Empresas',
                opcoes: 'Opc.',
                versao: 'Versão',
                valor_cheio: 'Valor Cheio',
                valor_negociado: 'Valor Negociado',
                valor_total_contrato: 'Valor Total Contrato',
                obs: 'Observações',
              },
            },
            form: {
              empresas_id: 'Empresa',
              cadastros_id: 'Cliente',
              indicacao_cadastros_id: 'Indicação',
              tipo_proposta_id: 'Tipo de Proposta',
              renovacao_propostas_id: 'Renovação Proposta',
              renovacao: 'Renovação',
              ativo: 'Ativo',
              avisar_antecedencia: 'Avisar Antecedência',
              validade: 'Validade',
              recorrente: 'Meses Recorrente',
              propostaRecorrente: 'Proposta Recorrente?',
              titulo: 'Título',
              versao: 'Versão',
              ativoVersao: 'Ativo Versão',
              data_inicio: 'Data Início',
              data_fim: 'Data Fim',
              valor_cheio: 'Valor Cheio',
              valor_negociado: 'Valor Negociado',
              valor_total_contrato: 'Valor Total Contrato',
              obs: 'Observações',
              versoes: 'Versões',
            },
            erros: {
              required: 'Este campo é obrigatório',
              min3: 'Mínimo de 3 caracteres',
              min8: 'Mínimo de 8 caracteres',
              email: 'Email inválido',
              cpf: 'CPF inválido',
              pattern: 'Preencha corretamente o campo com números',
              error: {
                list: 'Erro ao listar',
                delete: 'Erro ao deletar',
                filtros: 'Erro ao filtrar',
                add: 'Erro ao realizar cadastro',
                edit: 'Erro ao editar',
              },
            },
            success: {
              delete: 'deletado com sucesso',
              add: 'cadastrado com sucesso',
              edit: 'editado com sucesso',
            },
            button: {
              save: 'Salvar',
              edit: 'Editar',
              delete: 'Excluir',
              cancel: 'Cancelar',
            }
          },
          contratos: {
            title: 'Contratos',
            newVersion: 'Nova Versão',
            cadastrar: 'Novo Contrato',
            editar: 'Editar Contrato',
            visualizar: 'Visualizar Contrato',
            dias: 'dias',
            dia: 'dia',
            di: 'di',
            meses: 'meses',
            mese: 'mese',
            mes: 'mês',
            me: 'mê',
            currency: 'R$',
            addBySystem: 'Adicionado pelo sistema',
            nothing: 'Nenhuma versão cadastrada',
            addVersion: 'Adicionar Versão',
            addMore: 'Adicionar Mais',
            table: {
              head: {
                id: 'ID',
                titulo: 'Título',
                nome: 'Nome',
                descricao: 'Descrição',
                ativo: 'Ativo',
                cadastros: 'Cadastros',
                empresas: 'Empresas',
                opcoes: 'Opc.',
                versao: 'Versão',
                valor_cheio: 'Valor Cheio',
                valor_negociado: 'Valor Negociado',
                valor_total_contrato: 'Valor Total Contrato',
                obs: 'Observações',
              },
            },
            form: {
              empresas_id: 'Empresa',
              cadastros_id: 'Cliente',
              indicacao_cadastros_id: 'Indicação',
              tipo_contrato_id: 'Tipo de Contrato',
              renovacao_contratos_id: 'Renovação Contrato',
              renovacao: 'Renovação',
              ativo: 'Ativo',
              avisar_antecedencia: 'Avisar Antecedência',
              validade: 'Validade',
              recorrente: 'Meses Recorrente',
              contratoRecorrente: 'Contrato Recorrente?',
              titulo: 'Título',
              versao: 'Versão',
              ativoVersao: 'Ativo Versão',
              data_inicio: 'Data Início',
              data_fim: 'Data Fim',
              valor_cheio: 'Valor Cheio',
              valor_negociado: 'Valor Negociado',
              valor_total_contrato: 'Valor Total Contrato',
              obs: 'Observações',
              versoes: 'Versões',
              nome: 'Nome',
              descricao: 'Descrição',
              propostas_id: 'Proposta',
            },
            erros: {
              required: 'Este campo é obrigatório',
              min3: 'Mínimo de 3 caracteres',
              min8: 'Mínimo de 8 caracteres',
              email: 'Email inválido',
              cpf: 'CPF inválido',
              pattern: 'Preencha corretamente o campo com números',
              error: {
                list: 'Erro ao listar',
                delete: 'Erro ao deletar',
                filtros: 'Erro ao filtrar',
                add: 'Erro ao realizar cadastro',
                edit: 'Erro ao editar',
              },
            },
            success: {
              delete: 'deletado com sucesso',
              add: 'cadastrado com sucesso',
              edit: 'editado com sucesso',
            },
            button: {
              save: 'Salvar',
              edit: 'Editar',
              delete: 'Excluir',
              cancel: 'Cancelar',
            }
          },
          parametros: {
            title: 'Parâmetros',
            erros: {
              required: 'Este campo é obrigatório',
              error: {
                add: 'Erro ao adicionar parâmetro',
                edit: 'Erro ao atualizar parâmetro',
                delete: 'Erro ao deletar parâmetro',
                list: 'Erro ao listar parâmetros',
                min3: 'Mínimo de 3 caracteres',
              },
              notFound: 'Nenhum Parâmetro encontrado',
            },
            success: {
              add: 'Parâmetro adicionado com sucesso',
              edit: 'Parâmetro atualizado com sucesso',
              delete: 'Parâmetro deletado com sucesso',
            },
            form: {
              titleAdd: 'Adicionar Parâmetro',
              titleEdit: 'Editar Parâmetro',
              departamento: 'Parâmetro',
              empresa: 'Empresa',
              descricao: 'Descrição',
              ativo: 'Ativo?',
            },
            button: {
              add: 'Adicionar',
              edit: 'Editar',
              delete: 'Deletar',
              save: 'Salvar',
            }
          },
        },
        'es': {
          usuarios: {
            title: 'Usuarios',
            cadastrar: 'Registrar Usuario',
            editar: 'Editar Usuario',
            visualizar: 'Ver Usuario',
            table: {
              head: {
                id: 'ID',
                nome: 'Nombre',
                login: 'Login',
                admin: 'Admin',
                ativo: 'Activo',
                lastLogin: 'Último acceso',
                opcoes: 'Opc.',
              },
              exibir: 'Mostrar',
              filtrar: 'Filtrar',
              limpar: 'Limpiar',
              erros: {
                required: 'Este campo es obligatorio',
                min3: 'Un mínimo de 3 caracteres',
                min8: 'Un mínimo de 8 caracteres',
                email: 'Email inválido',
                cpf: 'CPF inválido',
                error: {
                  list: 'Error al listar',
                  delete: 'Error al borrar',
                  filtros: 'Error al filtrar',
                  add: 'Error al registrar',
                },
              },
              success: {
                delete: 'borrado con éxito',
                add: 'registrado con éxito',
              },
            },
            form: {
              nome: 'Nombre',
              email: 'Email',
              loginWithEmail: 'Iniciar sesión con correo electrónico?',
              login: 'Login',
              senha: 'Contraseña',
              cpf: 'CPF',
              contato: 'Contacto',
              ativo: 'Activo?',
              admin: 'Admin?',
              menu_lateral: 'Menú lateral?',
              alterar_senha: '¿Cambiar contraseña?',
              editar_perfil: 'Editar perfil?',
              fileTitle: 'Enviar imagen',
              imageProfile: 'Imagen de perfil',
            },
            button: {
              save: 'Guardar',
            }
          },
          grupos: {
            title: 'Grupos',
            cadastrar: 'Registrar Grupo',
            editar: 'Editar Grupo',
            visualizar: 'Ver Grupo',
            table: {
              head: {
                id: 'ID',
                nome: 'Nombre',
                admins: 'Administradores',
                users: 'Usuarios',
                date: 'Fecha de creación',
                opcoes: 'Opc.',
              },
            },
            errors: {
              add: 'Error al agregar grupo',
              required: 'Este campo es obligatorio',
            },
            success: {
              add: 'Grupo agregado con éxito',
            },
            steps: {
              info: 'Información del Grupo',
              users: 'Usuarios del Grupo',
            },
            form: {
              nome: 'Nombre del Grupo',
              tipo: 'Tipo de Grupo',
              descricao: 'Descripción',
              users: 'Usuarios',
              empresas: 'Empresa',
              admin: 'Usuario Administrador',
              title: 'Usuarios del Grupo'
            },
            button: {
              finish: 'Finalizar',
              back: 'Volver',
              next: 'Siguiente',
            }
          },
          tarefas: {
            title: 'Tareas',
            titleName: 'Tarea',
            new: 'Nueva Tarea',
            loadMore: 'Cargar más',
            label: 'Etiqueta',
            responsible: 'Responsables',
            search: 'Buscar',
            button: {
              edit: 'Editar',
              cancel: 'Cancelar',
              save: 'Guardar',
            },
            filter: {
              title: 'Filtros',
              favorites: 'Favoritos',
              finished: 'Finalizadas',
              files: 'Archivos',
              recorrente: 'Recurrente',
              notify: 'Notificar',
              clear: 'Limpiar Filtros',
            },
            erros: {
              required: 'Este campo es obligatorio',
              error: {
                add: 'Error al agregar tarea',
                edit: 'Error al actualizar tarea',
                delete: 'Error al borrar tarea',
                list: 'Error al listar tareas',
                favorite: 'Error al marcar tarea como favorita',
                complete: 'Error al completar tarea',
                loadMore: 'Cargar más',
                label: 'Etiqueta',
                responsible: 'Responsables',
                search: 'Buscar',
                anexo: 'Error al agregar archivo adjunto',
                min3: 'Un mínimo de 3 caracteres',
                dateInicial: 'Fecha de inicio es obligatoria',
                dateFinal: 'Fecha de finalización es obligatoria',
                downloadFile: 'Error al descargar archivo',
                deleteFile: 'Error al borrar archivo',
                subTaskAdd: 'Error al agregar subtarea',
                subTaskEdit: 'Error al editar subtarea',
                subTaskDelete: 'Error al borrar subtarea',
                commentsAdd: 'Error al agregar comentario',
                commentsEdit: 'Error al editar comentario',
                commentsDelete: 'Error al borrar comentario',
                flagsAdd: 'Error al agregar etiqueta',
                flagsEdit: 'Error al editar etiqueta',
                flagsDelete: 'Error al borrar etiqueta',
              },
              notFound: 'No se encontraron tareas',
            },
            success: {
              add: 'Tarea agregada con éxito',
              edit: 'Tarea actualizada con éxito',
              delete: 'Tarea borrada con éxito',
              favorite: 'Tarea marcada como favorita con éxito',
              complete: 'Tarea completada con éxito',
              anexo: 'Archivo adjunto agregado con éxito',
              downloadFile: 'Archivo descargado con éxito',
              deleteFile: 'Archivo borrado con éxito',
              subTaskAdd: 'Subtarea agregada con éxito',
              subTaskEdit: 'Subtarea editada con éxito',
              subTaskDelete: 'Subtarea borrada con éxito',
              commentsAdd: 'Comentario agregado con éxito',
              commentsEdit: 'Comentario editado con éxito',
              commentsDelete: 'Comentario borrado con éxito',
              flagsAdd: 'Etiqueta agregada con éxito',
              flagsEdit: 'Etiqueta editada con éxito',
              flagsDelete: 'Etiqueta borrada con éxito',
            },
            form: {
              title: 'Título',
              responsible: 'Responsable',
              dateInicial: 'Fecha de inicio',
              dateFinal: 'Fecha de finalización',
              description: 'Descripción de la tarea',
              notify: '¿Notificar?',
              recorrente: '¿Recurrente?',
              titleFlag: 'Título de la etiqueta',
            },
            files: {
              title: 'Archivos adjuntos',
              addFiles: 'Agregar archivos',
              added: 'Archivos agregados',
            },
            subTasks: {
              title: 'Subtareas',
              new: 'Nueva subtarea',
            },
            comments: {
              creatorOf: 'Creó este comentario',
              editComment: 'Editar comentario',
              writeComment: 'Escribe un comentario',
              comment: 'Comentar'
            },
            flags: {
              new: 'Nueva etiqueta',
              bgColor: 'Color de fondo',
            }
          },
          departamentos: {
            title: 'Departamentos',
            erros: {
              required: 'Este campo es obligatorio',
              error: {
                add: 'Error al agregar departamento',
                edit: 'Error al actualizar departamento',
                delete: 'Error al borrar departamento',
                list: 'Error al listar departamentos',
                min3: 'Un mínimo de 3 caracteres',
              },
              notFound: 'No se encontraron departamentos',
            },
            success: {
              add: 'Departamento agregado con éxito',
              edit: 'Departamento actualizado con éxito',
              delete: 'Departamento borrado con éxito',
            },
            form: {
              titleAdd: 'Agregar departamento',
              titleEdit: 'Editar departamento',
              departamento: 'Departamento',
              empresa: 'Empresa',
              descricao: 'Descripción',
              ativo: 'Activo?',
            },
            button: {
              add: 'Agregar',
              edit: 'Editar',
              delete: 'Borrar',
              save: 'Guardar',
            }
          },
          programas: {
            title: 'Programas',
            cadastrar: 'Registrar Programa',
            editar: 'Editar Programa',
            visualizar: 'Ver Programa',
            table: {
              head: {
                id: 'ID',
                modulo: 'Módulo',
                programa: 'Programa',
                rota: 'Ruta',
                exibirMenu: 'Mostrar en el menú',
                filtros: 'Filtros',
                acoes: 'Acciones',
                interacoes: 'Interacciones',
                functions: 'Funciones',
                icone: 'Ícono',
                opcoes: 'Opc.',
              },
            },
            error: {
              required: 'Este campo es obligatorio',
              add: 'Error al agregar programa',
            },
            success: {
              add: 'Programa agregado con éxito',
            },
            form: {
              modulo: 'Módulo',
              controller: '¿Crear controlador automáticamente?',
              exibirMenu: '¿Mostrar en el menú?',
              programa: 'Programa',
              rota: 'Ruta',
              icone: 'Ícono',
              botoesMenu: 'Botones del menú',
              acoes: 'Acciones',
              interacoes: 'Interacciones',
              opcoes: 'Opciones',
              banco: 'Base de datos',
              tabela: 'Tabla',
              pasta: 'Carpeta',
              oque: 'qué',
              comparativo: 'comparativo',
              com: 'con',
              campo: 'Campo',
              label: 'Etiqueta',
              comparador: 'Comparador',
              tipoCampo: 'Tipo de Campo',
              classe: 'Clase',
            },
            button: {
              save: 'Guardar',
            }
          },
          modulos: {
            title: 'Módulos',
            cadastrar: 'Registrar Módulo',
            editar: 'Editar Módulo',
            visualizar: 'Ver Módulo',
            table: {
              head: {
                id: 'ID',
                modulo: 'Módulo',
                rota: 'Ruta',
                menu: 'Menú',
                subMenu: 'Submenú',
                opcoes: 'Opc.',
              },
            },
            error: {
              required: 'Este campo es obligatorio',
              add: 'Error al agregar programa',
              edit: 'Error al editar programa',
              max50: 'Un máximo de 50 caracteres',
            },
            success: {
              add: 'Programa agregado con éxito',
              edit: 'Programa editado con éxito',
            },
            form: {
              modulo: 'Módulo',
              rota: 'Ruta',
              menu: '¿Mostrar en el menú?',
              prefixo: 'Insertar prefijo',
              controller: '¿Crear controlador automáticamente?',
            },
            button: {
              save: 'Guardar',
            }
          }
        },
      }
      if(Object.keys(responseUser.data).length===0) throw new Error('Usuário não encontrado');
      const response=responseData.data.data.objetos[0];
      setUser(responseUser.data);
      setLanguage(languages['pt-br']);
      authToken(localStorage.getItem('expiresIn'));
      setResponse(response);
      const itemsMapped=[
        // {
        //     id: 'visao-geral',
        //     title: 'Visão Geral',
        //     type: 'group',
        //     children: [
        //       {
        //         id: 'mapa',
        //         title: 'Mapa',
        //         type: 'item',
        //         icon: <Icon iconName="Map" />,
        //         url: '/mapa',
        //         breadcrumbs: false
        //       },
        //     ]
        //   }
        ];
      // if(process.env.REACT_APP_ENVIRONMENT!='production') {
      //   itemsMapped.push({
      //     id: 'visao-geral',
      //     title: 'Visão Geral',
      //     type: 'group',
      //     children: [
      //       {
      //         id: 'controle-de-carros',
      //         title: 'Controle de Carros',
      //         type: 'item',
      //         icon: <Icon iconName='Dashboard' />,
      //         url: '/controle-de-carros',
      //         breadcrumbs: false
      //       },
      //       {
      //         id: 'mapa',
      //         title: 'Mapa',
      //         type: 'item',
      //         icon: <Icon iconName="Map" />,
      //         url: '/mapa',
      //         breadcrumbs: false
      //       },
      //       {
      //         id: 'funcionarios',
      //         title: 'Funcionários',
      //         acoes: {
      //           listar: 'Listar',
      //           cadastrar: 'Cadastrar',
      //         },
      //         type: 'collapse',
      //         icon: <Icon iconName='Article' />,
      //         children: [
      //           {
      //             id: `funcionarios/listar`,
      //             title: 'Listar',
      //             type: 'item',
      //             url: `/funcionarios/listar`,
      //             breadcrumbs: false
      //           },
      //           {
      //             id: `funcionarios/cadastrar`,
      //             title: 'Cadastrar',
      //             type: 'item',
      //             url: `/funcionarios/cadastrar`,
      //             breadcrumbs: false
      //           },
      //         ]
      //       },
      //       {
      //         id: 'cargo-salario',
      //         title: 'Cargo e Salário',
      //         acoes: {
      //           listar: 'Listar',
      //           cadastrar: 'Cadastrar',
      //         },
      //         type: 'collapse',
      //         icon: <Icon iconName='Article' />,
      //         children: [
      //           {
      //             id: `cargo-salario/listar`,
      //             title: 'Listar',
      //             type: 'item',
      //             url: `/cargo-salario/listar`,
      //             breadcrumbs: false
      //           },
      //           {
      //             id: `cargo-salario/cadastrar`,
      //             title: 'Cadastrar',
      //             type: 'item',
      //             url: `/cargo-salario/cadastrar`,
      //             breadcrumbs: false
      //           },
      //         ]
      //       },
      //     ]
      //   },
      //   );
      // }
      await itensKeys.forEach((chave) => {
        if(chave in response.menu) {
          if(response.menu[chave].programas.length===0) return;
          const childrenMenu=Object.keys(response.menu[chave].programas).map((key) => {
            const haveChildren=response.menu[chave].programas[key].botoesMenu===null? false:Object.keys(response.menu[chave].programas[key].botoesMenu).length>1;
            if(haveChildren) {
              let children=[]
              if(response.menu[chave].programas[key].botoesMenu) {
                const keys=Object.keys(response.menu[chave].programas[key].botoesMenu);
                keys.map((chaveKeys) => {
                  children.push({
                    id: `${response.menu[chave].programas[key].rota}/${chaveKeys}`,
                    title: response.menu[chave].programas[key].botoesMenu[chaveKeys],
                    type: 'item',
                    url: `/${response.menu[chave].rota}/${response.menu[chave].programas[key].rota}/${chaveKeys}`,
                    breadcrumbs: false
                  })
                })
              }
              if(response.menu[chave].programas[key].opcoes) {
                const keys=Object.keys(response.menu[chave].programas[key].opcoes);
                keys.map((chaveKeys) => {
                  if(chaveKeys==='editar') {
                    response.menu[chave].programas[key].botoesMenu.editar='Editar';
                  }
                })
              }
              return {
                id: response.menu[chave].programas[key].rota,
                title: response.menu[chave].programas[key].programa,
                acoes: response.menu[chave].programas[key].acoes,
                type: 'collapse',
                icon: <Icon iconName={response.menu[chave].programas[key].icone} />,
                telaListar: response.menu[chave].programas[key].telaListar,
                botoesMenu: response.menu[chave].programas[key].botoesMenu,
                children: children
              }
            } else {
              return {
                id: response.menu[chave].programas[key].rota,
                title: response.menu[chave].programas[key].programa,
                type: 'item',
                icon: <Icon iconName={response.menu[chave].programas[key].icone} />,
                url: `/${response.menu[chave].rota}/${response.menu[chave].programas[key].rota}`,
                breadcrumbs: false,
                telaListar: response.menu[chave].programas[key].telaListar,
              };
            }
          });
          const menuItem={
            id: response.menu[chave].rota,
            title: response.menu[chave].modulo,
            type: 'group',
            children: childrenMenu
          };
          itemsMapped.push(menuItem);
        }
      });
      setItems([...itemsMapped]);
    } catch(error) {
      dispatch(showSnackbar('Falha ao pegar informações o usuário','error'))
      navigate('/login');
    } finally {
      setLoading(false);
    }
  };

  const authToken = (expiresIn) => {
    const interval = setInterval(async () => {
      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/me`, {}, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
          }
        });
      } catch {
        navigate('/login');
      }
    }, expiresIn * 1000); // tempo do token em milissegundos
  
    return () => clearInterval(interval);
  };

  return (
    <ConfigMenuContext.Provider value={{
      response,
      loading,
      items,
      user,
      language,
      FetchMenu
    }}>
      {children}
    </ConfigMenuContext.Provider>
  );
};

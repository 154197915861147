export default function componentStyleOverrides(theme) {
  const bgColor=theme.colors?.grey50;

  return {
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-head': {
            color: theme?.colors?.resoWhite,
            backgroundColor: theme?.colors?.tableHeader,
          },
          '& .MuiTableRow-head': {
            backgroundColor: theme?.colors?.tableBorder,
            border: `1px solid ${theme?.colors?.tableHeader} !important`
          }
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          color: theme?.colors?.resoBlack,
          borderBottom: `1px solid ${theme?.colors?.tableBorder} !important`,
          borderLeft: `1px solid ${theme?.colors?.tableBorder} !important`,
          borderRight: `1px solid ${theme?.colors?.tableBorder} !important`,
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)',
            color: theme?.colors?.resoBlack
          },
          '&.Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)',
            color: theme?.colors?.resoBlack
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: `none !important`,
          color: theme.primaryPinePr && 'black',
        }
      }
    },

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none !important',
          backgroundColor: `${theme?.button} !important`,
          color: `${theme?.buttonText} !important`,
          border: `1px solid ${theme.colors.tableBorder} !important`,
          borderColor: `none !important`,
          '&:hover': {
            backgroundColor: `${theme?.buttonHover} !important`,
            border: '1px solid !important',
            borderColor: `${theme?.colors?.resoOrange} !important`,
            color: `${theme?.colors?.resoOrange} !important`
          }
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          backgroundColor: theme.colors.backgroundDefault
        },
        rounded: {
          borderRadius: `${theme?.customization?.borderRadius}px`
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.colors?.textDark,
          padding: '24px'
        },
        title: {
          fontSize: '1.125rem'
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px'
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '24px'
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: theme.primaryPinePr ? theme.primaryPinePr : theme.darkTextPrimary,
          paddingTop: '10px',
          paddingBottom: '10px',
          '& .MuiListItemIcon-root': {
            color: theme.primaryPinePr ? theme.primaryPinePr : theme.darkTextPrimary,
          },
          '&.Mui-selected': {
            color: theme.darkTextPrimary,
            backgroundColor: theme.menuSelectedBack,
            '&:hover': {
              backgroundColor: theme.menuSelectedBack
            },
            '& .MuiListItemIcon-root': {
              color: theme.darkTextPrimary
            }
          },
          '&:hover': {
            backgroundColor: theme.menuSelectedBack,
            color: theme.darkTextPrimary,
            '& .MuiListItemIcon-root': {
              color: theme.darkTextPrimary
            }
          }
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.darkTextPrimary,
          minWidth: '36px'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: theme.textDark
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: theme.textDark,
          '&::placeholder': {
            color: theme.darkTextSecondary,
            fontSize: '0.875rem'
          },
          backgroundColor: `${theme.colors.primaryLight} !important`,
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          color: theme.textDark,
          '&::placeholder': {
            color: theme.darkTextSecondary,
            fontSize: '0.875rem'
          },
          backgroundColor: `${theme.colors.backgroundDefault}`
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            "-webkit-text-fill-color": theme.button ? theme.button : 'rgba(255, 255, 255, 1)',
            opacity: 0.6
          }
        },
        outlined: {
          '&.Mui-focused': {
            color: theme?.colors?.resoHoverOrange
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: theme.colors.primaryLight,
          borderRadius: `${Number(theme?.customization?.borderRadius)+2}px`,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.colors.tableBorder,
            borderRadius: `${Number(theme?.customization?.borderRadius)+2}px`,
          },
          '&:hover $notchedOutline': {
            borderColor: theme.colors.tableBorder
          },
          '&.MuiInputBase-multiline': {
            padding: 1
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.colors.tableBorder
          },
        },
        input: {
          fontWeight: 500,
          background: bgColor,
          padding: '15.5px 14px',
          borderRadius: `${Number(theme?.customization?.borderRadius)+2}px`,
          '&.MuiInputBase-inputSizeSmall': {
            padding: '10px 14px',
            '&.MuiInputBase-inputAdornedStart': {
              paddingLeft: 0
            }
          },
          "&.Mui-disabled": {
            "-webkit-text-fill-color": theme.button ? theme.button : 'rgba(255, 255, 255, 1)',
            opacity: 0.6
          },
        },
        inputAdornedStart: {
          paddingLeft: 4
        },
        notchedOutline: {
          borderRadius: `${theme?.customization?.borderRadius}px`
        },
      }
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: theme.colors?.grey300
          }
        },
        mark: {
          backgroundColor: theme.paper,
          width: '4px'
        },
        valueLabel: {
          color: theme?.colors?.primaryLight
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.colors.tableBorder,
          opacity: 1
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: 'white !important',
          backgroundColor: `${theme.button} !important`,
          ':hover': {
            backgroundColor: `${theme.buttonHover} !important`
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: theme.textColor,
          background: theme?.colors?.primaryLight,
          '&.MuiChip-deletable .MuiChip-deleteIcon': {
            color: theme.textColor
          },
          "&.Mui-disabled": {
            // "-webkit-text-fill-color": "rgba(255, 255, 255, 1)",
            opacity: 0.6
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.paper,
          background: theme.colors?.grey700
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          // color: theme.colors?.resoWhite,
          '&:hover': {
            // color: theme.colors?.resoOrange,
            backgroundColor: theme.colors?.resoHoverOrange
          },
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          '&.Mui-disabled': {
            // "-webkit-text-fill-color": "rgba(255, 255, 255, 1)",
            opacity: 0.6
          }
        }
      }
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          borderColor: theme.colors.tableBorder
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          paddingLeft: '2px',
          '@media (min-width: 600px)': {
            paddingLeft: '2px'
          }
        }
      }
    },
    MuiMultiSectionDigitalClock: {
      styleOverrides: {
        root: {
          '&&': {
            '.MuiList-root': {
              width: '65px',
              marginLeft: '5px',
            },
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // Controls default (unchecked) color for the thumb
          color: "#ccc"
        },
        colorPrimary: {
          "&.Mui-checked": {
            // Controls checked color for the thumb
            color: theme.buttonHover
          }
        },
        track: {
          // Controls default (unchecked) color for the track
          opacity: 0.2,
          backgroundColor: theme.button,
          ".Mui-checked.Mui-checked + &": {
            // Controls checked color for the track
            opacity: 0.7,
            backgroundColor: theme.button
          }
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: theme.button,
          '&.Mui-active': {
            color: theme.buttonHover
          },
          '&.Mui-completed': {
            color: theme.buttonHover
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: theme.button,
          '&.Mui-checked': {
            color: theme.buttonHover
          }
        }
      }
    },
  };
}

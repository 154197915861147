import {useEffect,useRef} from 'react';
import {dia,ui,shapes} from '@joint/plus';
import MainCard from 'ui-component/cards/MainCard';

function Actions() {

    const canvas=useRef(null);

    useEffect(() => {
        const graph=new dia.Graph();

        const paper=new dia.Paper({
            model: graph,
            background: {
                color: '#F8F9FA',
            },
            frozen: true,
            async: true,
            cellViewNamespace: shapes
        });

        const scroller=new ui.PaperScroller({
            paper,
            autoResizePaper: true,
            cursor: 'grab'
        });

        canvas.current?.appendChild(scroller.el);
        scroller.render().center();

        const rect=new shapes.standard.Rectangle({
            position: {x: 100,y: 100},
            size: {width: 100,height: 50},
            attrs: {
                label: {
                    text: 'Hello World'
                }
            }
        });

        graph.addCell(rect);
        paper.unfreeze();

        return () => {
            scroller.remove();
            paper.remove();
        };

    },[]);

    return (
        <MainCard title="Actions" isOption>
            <div className="canvas" ref={canvas} />
        </MainCard>
    );
}

export default Actions;